class ZIDXThankYou implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxThankYouContainer";
    }
    getMatchURL(){
        return "/thank-you/";
    }
    render(){
        // console.log("thank you");

    }
}